import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useGames } from "../../hooks/useGames";
import { CategoryEnum } from "../../lib/interfaces/custom.types";
import GameCard from "./GameCard";

interface GamesRowProps {
  category: CategoryEnum;
  cardsPerRow: number;
}

export default function GamesRow({ category, cardsPerRow }: GamesRowProps) {
  const { isGamesLoading, games } = useGames({ category, name: "" });

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    if (games.length > 0) {
      setTimeout(() => {
        sliderRef?.current?.slickGoTo(Math.min(currentSlide, games.length - cardsPerRow));
      }, 10);
    }
  }, [cardsPerRow, currentSlide, games]);

  return (
    <div ref={parentRef} className="mb-1"> {/* Added margin-bottom to ensure cards are not cut off */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold mt-4 mb-2">
          {category} Games
        </h2>
        <a href="#" className="text-purple-400 hover:text-purple-100 transition-colors duration-300">
          View more
        </a>
      </div>

      <Slider
        ref={sliderRef}
        beforeChange={(_, nextSlide) => setCurrentSlide(nextSlide)}
        centerPadding="10px"
        rows={1}
        dots={false}
        arrows={false}
        infinite={false}
        speed={300}
        swipeToSlide
        variableWidth={true}
        // Added adaptiveHeight to adjust slider height dynamically based on card height
      >
        {isGamesLoading
          ? Array.from({ length: cardsPerRow }, (_, index) => (
              <div key={index} className="mr-6 mb-2 rounded-[6px] overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-lg">
                <div className="relative w-[250px] h-[160px] bg-black bg-opacity-50 flex items-center justify-center">
                  <div className="w-8 h-8 border-2 border-white rounded-full border-t-white border-b-transparent animate-spin"></div>
                </div>
              </div>
            ))
          : games.map((game) => (
              <GameCard key={game.id} game={game} />
            ))
        }
      </Slider>
    </div>
  )
}