import Image from "next/image";
import { GameEntity } from "../../lib/interfaces/custom.types";

interface GameCardProps {
  game: GameEntity;
}

const GameCard = ({ game }: GameCardProps) => {
  return (
    <div className="mr-6 mb-2 rounded-[6px] overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-lg">
      <a href={`/app/${game.id}`} className="block w-full h-full">
        <div className="relative w-[250px] h-[160px]">
          <Image
            src={game.thumbnailUrl}
            alt={game.gameName}
            layout="responsive"
            width={250}
            height={160}
            objectFit="contain"
            className="transition-transform duration-300 hover:scale-110"
          />
        </div>
      </a>
    </div>
  )
}

export default GameCard;